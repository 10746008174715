export default {
  hello: 'Que tenga un lindo día',
  loanRecords: 'Historial de préstamos',
  repaymentRecords: 'Historial de pagos',
  myCoupon: 'Mi cupón de descuento',
  ExcitingEvents: 'Estamos preparando eventos y premios especiales.',
  activity: 'Actividad',
  dataMgmt: 'Gestión de datos',
  excitingEvents: 'Eventos Especiales',
  discountCoupons: 'Cupones de Descuento',
  limitIncrease: 'Aumento de Límite',
  LogInandRegister: 'Inicia sesión ahora',
  hasta: 'Hasta',
  currentVersion: 'Versión en uso',
  feedback: 'Comentarios',
  contactUs: 'Contáctenos',
  logOut: 'Desconectar',
  deleteAccount: 'Borrar cuenta',
  confirmLogOutDes:
    'Después de cerrar sesión, borraremos su estado de inicio de sesión y lo ayudaremos a saltar a la página de inicio de sesión.',
  settings: 'Configuración',
  deleteAccountDesc:
    'Antes de eliminar la cuenta, asegúrese de que su préstamo haya sido reembolsado en su totalidad y que no haya servicios de préstamo en curso actualmente; de lo contrario, la eliminación de la cuenta fallará. Después de eliminar su cuenta, será expulsado de la ventana de inicio de sesión. Si vuelve a iniciar sesión, se considerará que se ha vuelto a registrar.',
  changePassword: 'Cambiar la contraseña',
  confirmLogOutTitle: '¿Estás seguro de dejarlo?',
  confirmLogOut: 'Confirmar eliminación',
  deleteAccountTitle: '¿Estás seguro de que quieres eliminar tu cuenta?',
  numberLoans: 'Número de préstamos: ',
  commonFunctions: 'Funciones Comunes',
  otherFunctions: 'Otras funciones',
  aboutUs: 'Acerca de Nosotros',
  version: 'Versión',
  privacyAgreement: 'Acuerdo de Privacidad',
  termsAndConditions: 'Términos y Condiciones',
  loanContract2: 'Contrato de prestamo',
  onlineService: 'Servicio en línea',
  pleaseSelectSubject: 'Por favor, elija el tema al que desea responder.',
  pleaseProblem: 'Por favor, describa el problema que ha encontrado.',
  emergencySituations: '¿Situaciones de emergencia? Contactar con servicio humano',
  feedbackSuccessful: 'La retroalimentación fue exitosa',
  onlineSupport: 'Soporte en línea',
  questionType: 'Tipo de pregunta',
  attachmentOptional: 'Archivo adjunto (opcional)',
  confirmDeletion: 'Confirmar la eliminación',
  // 新
  chooseFinancialProducts:
    'Insistimos en estar centrados en el cliente y utilizar la tecnología al servicio de las pequeñas y medianas empresas. Basándonos en arquitecturas subyacentes como la inteligencia artificial, la nube financiera, la cadena de bloques y el Internet de las cosas, construimos un cerebro financiero que opera utilizando tecnologías como la computación emocional, las redes de relaciones y los gráficos de conocimiento como motor para la percepción y la toma de decisiones. La capacidad media diaria de procesamiento de big data alcanza los 100 elementos. Después de miles de millones de cálculos, los robots RPA han reemplazado más del 60% de las tareas repetitivas en la revisión crediticia, aplicando capacidades técnicas líderes en marketing inteligente, control de riesgos inteligente, gestión inteligente, operaciones inteligentes, financiamiento de cadena completa e identificación precisa. crédito. Características de riesgo, utilizando tecnología para ayudar a los usuarios a reducir los costos de financiamiento.',
  userStories: 'Historias de usuarios',
  seeMore: 'see more',
  userStoriesTitle1: 'De 0 a 5 millones, como di la vuelta',
  userStoriesInfo1: 'Cuando se trata de emprendimiento, el Sr. Hao es uno de los afortunados',
  userStoriesTitle2: 'Llevo diez años en el sector de la restauración.',
  userStoriesInfo2:
    'El Sr. Liao ha trabajado en la industria de la restauración durante más de diez años, ha experimentado la época dorada de la industria de la restauración y ahora se ha topado con un punto bajo en su vida.',
  userStoriesTitle3: 'La transformación de los "grandes fans" en la industria del entretenimiento',
  userStoriesInfo3:
    '"He estado en esta industria durante más de diez años y he conocido a mucha gente en la industria del entretenimiento". El Sr. Yang de Guangdong ha sido emprendedor en la industria de la comunicación cultural y fundó dos empresas. Actualmente, también se encuentra con dificultades financieras.',
  applyLoanAmount: 'Solicitar el monto del préstamo',
  millionPeopleSuccessfully: '8,65 millones de personas han obtenido dinero prestado con éxito',
  nowBorrow: 'pedir prestado ahora',
  moreFeatures: 'Más características',
  account: 'Cuenta',
  // 贷款各种状态文本 - 1.审核中或审批回退或审批拒绝
  totalRequested: 'Monto total del préstamo solicitado',
  loanTerm: 'Plazo del préstamo',
  rollbackInfo1: 'La información del préstamo que envió puede ser incorrecta.',
  rollbackInfo2: 'Revise y modifique la siguiente información y vuelva a enviarla:',
  rollbackText1: '1. Verifique la información de su tarjeta de identificación.',
  rollbackText2: '2. Confirmar si la tarjeta bancaria pertenece al solicitante.',
  rollbackText3: '3. Verifique que el número de cuenta de la tarjeta bancaria sea correcto. ',
  modifyInformation: 'Modificar la información',
  refuseInfo1:
    'Lo siento, su solicitud de préstamo ha sido rechazada. Vuelva a solicitar después de 3 días.',
  refuseInfo2: 'Próxima fecha de solicitud',
  paymentInfo1:
    'La información de pago de su préstamo ha sido enviada al banco. Consulte el horario de servicio del banco local para conocer la hora específica de llegada.',
  rebates: 'Pagar',
};
