export type Config = {
  /**
   * 环境
   */
  env: 'dev' | 'test' | 'pro';
  /**
   * h5访问地址
   */
  url: {
    dev: string;
    test: string;
    pro: string;
  };
  /**
   * h5 app 中访问的 api 地址
   */
  h5_base_url: {
    dev: string;
    test: string;
    pro: string;
  };
};

export const config: Config = {
  env: process.env.REACT_APP_ENV as 'dev' | 'test' | 'pro',
  url: {
    dev: 'https://co-h5-vitalidadprestamos.test.loan.envtop.com/',
    test: 'https://co-h5-vitalidadprestamos.test.loan.envtop.com/',
    pro: 'https://h5.vitalidadprestamos.com/',
  },
  h5_base_url: {
    dev: '/h5/quark-loan-business-co-app',
    test: '/h5/quark-loan-business-co-app',
    pro: '/h5/quark-loan-business-co-app',
  },
};
